import Vue from 'vue'
import OurBrandsSection from '~/components/storyblok/OurBrandsSection.vue'
import OurBrandsBlock from '~/components/storyblok/OurBrandsBlock.vue'
import Hero from '~/components/storyblok/Hero.vue'
import HeroSlide from '~/components/storyblok/HeroSlide.vue'
import CardSlider from '~/components/storyblok/CardSlider.vue'
import CardSlide from '~/components/storyblok/CardSlide.vue'
import BrandsSliderItem from '~/components/storyblok/BrandsSliderItem.vue'
import BrandsSlider from '~/components/storyblok/BrandsSlider.vue'
import LoginComponent from '~/components/storyblok/LoginComponent.vue'
import RegisterComponent from '~/components/storyblok/RegisterComponenet.vue'
import SimplePageComponent from '~/components/storyblok/SimplePageComponenet.vue'
import AboutUsHeroBanner from '~/components/storyblok/AboutUsHeroBanner.vue'
import OurTeam from '~/components/storyblok/OurTeam.vue'
import AboutUsCeo from '~/components/storyblok/AboutUsCeo.vue'
import AboutUsCards from '~/components/storyblok/AboutUsCards.vue'
import AboutUsCardText from '~/components/storyblok/AboutUsCardText.vue'
import AboutUsOurMission from '~/components/storyblok/AboutUsOurMission.vue'
import WorldOfInspiration from '~/components/storyblok/inspirationPage/WorldOfInspiration.vue'
import BlogItem from '~/components/storyblok/inspirationPage/BlogItem.vue'
import BlogSection from '~/components/storyblok/inspirationPage/BlogSection.vue'
import BasicHero from '~/components/storyblok/BasicHero.vue'
import TeamComp from '~/components/storyblok/TeamComp.vue'
import ImageComp from '~/components/storyblok/ImageComp.vue'
import ContactUsForm from '~/components/storyblok/ContactUsForm.vue'
import Questions from '~/components/storyblok/customerSupportPage/Questions.vue'
import CustomerSupportContact from '~/components/storyblok/customerSupportPage/CustomerSupportContact.vue'
import CustomerSupportHero from '~/components/storyblok/customerSupportPage/CustomerSupportHero.vue'
import IconSb from '~/components/storyblok/IconSb.vue'
import GeneralMain from '~/components/storyblok/GeneralMain.vue'
import ArticleDetails from '~/components/storyblok/ArticleDetails.vue'
import Error404 from '~/components/storyblok/Error404.vue'
import ThankYou from '~/components/storyblok/ThankYou.vue'
import Button from '~/components/storyblok/Button.vue'



Vue.component('OurBrandsSection', OurBrandsSection)
Vue.component('OurBrands', OurBrandsBlock)
Vue.component('Hero', Hero)
Vue.component('HeroSlide', HeroSlide)
Vue.component('CardSlider', CardSlider)
Vue.component('CardSlide', CardSlide)
Vue.component('BrandsSliderItem', BrandsSliderItem)
Vue.component('BrandsSlider', BrandsSlider)
Vue.component('LoginComponent', LoginComponent)
Vue.component('RegisterComponent', RegisterComponent)
Vue.component('SimplePageComponent', SimplePageComponent)
Vue.component('AboutUsHeroBanner', AboutUsHeroBanner)
Vue.component('OurTeam', OurTeam)
Vue.component('AboutUsCeo', AboutUsCeo)
Vue.component('AboutUsCards', AboutUsCards)
Vue.component('AboutUsCardText', AboutUsCardText)
Vue.component('AboutUsOurMission', AboutUsOurMission)
Vue.component('WorldOfInspiration', WorldOfInspiration)
Vue.component('BlogItem', BlogItem)
Vue.component('BlogSection', BlogSection)
Vue.component('BasicHero', BasicHero)
Vue.component('TeamComp', TeamComp)
Vue.component('ImageComp', ImageComp)
Vue.component('ContactUsForm', ContactUsForm)
Vue.component('Questions', Questions)
Vue.component('CustomerSupportContact', CustomerSupportContact)
Vue.component('CustomerSupportHero', CustomerSupportHero)
Vue.component('IconSb', IconSb)
Vue.component('GeneralMain', GeneralMain)
Vue.component('Error404', Error404)
Vue.component('ArticleDetails', ArticleDetails)
Vue.component('ThankYou', ThankYou)
Vue.component('Button', Button)
