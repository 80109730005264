const checkIpAddr = async (context) => {
  return await context.app.$axios
    .get("https://api.ipdata.co/?api-key=" + context.$config.geoLocationIp)
    .then((data) => {
      return data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const checkAvailStores = async (context) => {
  const { data } = await context.app.$vsf.$magento.api.availableStores({ availableStores: "availableStores" }, {});
  return data;
};

const changeStore = (context, store_code, availStores) => {
  const newStoreUrl = "/" + store_code;
  window.open(newStoreUrl, "_self");
};

const plugin = async (context) => {
  const cCode = context.app.$cookies.get("ip-country");

  if (cCode !== undefined) return;

  const data = await checkIpAddr(context);

  if (!data) return;
  context.app.$cookies.set("ip-country", data.data.country_code, { maxAge: 31536000 });
  const availStores = await checkAvailStores(context);

  switch (data.data.country_code) {
    case "NL":
      changeStore(context, "nl", availStores);
      break;
    case "FR":
      changeStore(context, "fr", availStores);
      break;
    case "DE":
      changeStore(context, "de", availStores);
      break;
    case "ES":
      changeStore(context, "es", availStores);
      break;
    default:
      changeStore(context, "en", availStores);
      break;
  }
};
export default plugin;
