import { defineStore } from 'pinia';

interface addToCartPopupState {
  openPopup: any;
}

export const useAddToCartPopup = defineStore('addToCartPopup', {
  state: (): addToCartPopupState => ({
    openPopup: false,
  }),
  actions:{
    openAddToCartPopup (val) {
      this.openPopup = val;
    }
  }
});