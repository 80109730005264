import { defineStore } from 'pinia';

export const useScrollPageStore = defineStore('scrollPage', {
  state: () => ({
    scrollPosition: { x: 0, y: 0 },
    changePagePosition: null,
  }),

  actions: {
    setScrollPosition(position) {
      this.scrollPosition = position;
    },
    setChangePagePosition(position) {
      this.changePagePosition = position;
    },
  },
});