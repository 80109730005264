export default ({ app }, inject) => {
  inject('storyblok', (page: String) => {
      let lang = '';
      const locale = app.$vsf.$magento.config.locale
      switch (locale) {
        case "en":
          lang = ''
          break;
        case "fr":
            lang = 'fr'
            break;
        case "de":
          lang = 'de'
          break;
        case "nl":
          lang = 'nl'
          break;
        case "es":
            lang = 'es'
            break;
        default:
          lang = ''
      }
  
      let url = `cdn/stories/${page}?language=${lang}`
      // // This what would we do in real project
      const version = app.isDev ? 'draft' : 'published'
      // const fullSlug = (context.route.path == '/' || context.route.path == '') ? 'home' : context.route.path
   
      // Load the JSON from the API - loadig the home content (index page)
      return app.$storyapi.get(url, {
        version: version,
        resolve_links: 'url'
      }).then((res) => {
        return res.data
      }).catch((res) => {
        return res.response
      })
  });
};