import { defineStore } from 'pinia';

interface BrandsState {
  allBrands: any;
  allSubBrands: any;
  onlyBrands: [],
  brandAttributesForPrFeeds: []
}

export const useBrandsStore = defineStore('brands', {
  state: (): BrandsState => ({
    allBrands: [],
    allSubBrands: [],
    onlyBrands: [],
    brandAttributesForPrFeeds: []
  }),
  actions: {
    updateBrands (payload) {
      this.allBrands = []
      this.allBrands = [...this.allBrands, ...payload?.ambrandlist.items]
      this.onlyBrands = []
      this.onlyBrands = payload?.ambrandlist.items
      this.brandAttributesForPrFeeds = []
      this.brandAttributesForPrFeeds = payload?.ambrandlist.brand_attribute.attribute_options
    },
    updateSubBrands (payload) {
      const mappedSubBrands = payload.subBrands.map(b => {
        return {
          ...b,
          label: b.title,
          img: b.small_image
        }
      })
      this.allBrands = [...this.allBrands, ...mappedSubBrands]
      this.allSubBrands = mappedSubBrands
    }
  },
  getters: {
    getSpecificBrand (state) {
      return (brandId) => state.allBrands.find(brand => brand.url === brandId)
    },
    getBrandById (state) {
      return (brandId) => state.allBrands.find(brand => brand.brandId === parseInt(brandId))
    },
    filterForSearch(state) {
      return (brandId) => state.allBrands.filter(brand => {
        if(brand.label && brandId){
          return brand.label.toLowerCase().includes(brandId.toLowerCase())
        }
      })
    }
  }
});
