import type { Plugin } from '@nuxt/types';
import type { ApolloQueryResult } from '@apollo/client/core/types';
import type { UiNotification } from '~/composables/useUiNotification';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export const hasGraphqlAuthorizationError = (res: ApolloQueryResult<unknown>) => res?.errors
  ?.some((error) => error?.extensions?.category === 'graphql-authorization') ?? false;

const plugin : Plugin = ({ $pinia, app, redirect }) => {
  const customerStore = useCustomerStore($pinia);

  const customerUrl = `${app.$vsf.$magento.config.magentoBaseUrl}rest/V1/customers/me`;
  let customerToken = app.$vsf.$magento.config.state.getCustomerToken();
  const headers = {
    Authorization: "Bearer " + customerToken,
  };
  
  if(customerToken) {
    fetch(customerUrl, {
      headers,
    })
    .then((response) => response.json())
    .then((data) => {
      if(data.id) {
        customerStore.$reset()
        customerStore.setIsLoggedIn(true)
      } else {
        customerStore.setIsLoggedIn(false)
        customerStore.$reset()
      }
    })
  }

  app.$vsf.$magento.client.interceptors.response.use(async (res) => {
    if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>)) {
      return res;
    }
    customerStore.setIsLoggedIn(false);
    customerStore.$reset()
    app.$vsf.$magento.config.state.removeCustomerToken();
    app.$vsf.$magento.config.state.removeCartId();
    app.$vsf.$magento.config.state.setMessage<UiNotification>({
      id: Symbol(''),
      message: app.i18n.t('You are not authorized, please log in.') as string,
      type: 'warning',
      icon: null,
      persist: false,
      title: null,
    });

    app.$cookies.remove("sale-person")

    redirect(app.localePath('/login'))

    return res;
  });
};

export default plugin;
